import * as React from "react"
import { Link } from "gatsby"
import technologyImage from "../images/technologyrace.jpg"

import Layout from "../components/layout"
import SEO from "../components/seo"

const blog1 = () => (
  <Layout>
    <SEO title="Technology Race to Where!" />
    <div class="container col-lg-6">
      <article class="blog-post mt-3">
        <h2 class="blog-post-title">Technology Race To Where!</h2>
        <p class="blog-post-meta">March 18 2021 by Paul</p>
        <img src={technologyImage} class="card-img-top mb-3" alt="..." />

        <p>
          It is a hard world out there for Relocation companies with so many
          decisions to take and cashflow challenges. These companies are run by
          smart people with an intelligent workforce so they should be well
          placed to move with the times. But tough decisions need to be taken on
          their future direction. A technology race has been ongoing for many
          years, where the one with the latest upgrades gets ahead of the
          others. But where will this race end? In fame and glory or
          destruction?
        </p>
        <p>
          In the last few years, many relocation companies stared multiyear
          investment in new technology which was a positive for the Global
          Mobility community. But as these initiatives go live how many of those
          original technology goals and objectives are still relative in the new
          mobility world we now live in? Has the ‘go live’ of these technology
          solutions been delayed, or certain aspects put on hold? Relocation
          companies that are truly agile, see the future trends, act upon them
          decisively will be the winners in the technology solutions race and
          wider aspects of global mobility.
        </p>
        <p>
          But is this investment in technology by relocation providers
          sustainable? Will enough clients utilize the relocation providers
          technology capabilities so there is a return on investment? Or will
          their clients buy their own technology system or utilize the
          technology of other providers such as tax? Do not forget Relocation
          Companies don’t normally sell their technology as a standalone
          solution or charge separately for their technology to clients; it is
          rolled into the program of services you buy from them.
        </p>
        <p>
          The technology solution world of specialist providers in the Global
          mobility space from the well-known such as Equus and TOPIA through to
          the potentially lesser-known companies such as MoveAssist, ReloTalent
          and new entrants such as ReloTrust to name a few are all evolving
          their solutions at an ever-increasing pace. But this is their world,
          what they specialize in, so potentially it will be brave relocation
          companies that continue to invest so heavily in technology solutions,
          including bespoke systems in search of a competitive edge, as this
          will backfire if it doesn’t deliver added value for their clients. But
          let us not forget that brave people push boundaries and when
          successful reshape industries so let us watch this space!
        </p>
        <p>
          A challenge many Global Mobility professionals have faced when going
          to RFP for mobility services is deciding what they need from a
          provider’s technology. Some have explored this during an RFP, but you
          can easily get led in different directions from the multiple
          offerings. This is not ideal in the middle of an RFP, when you may
          also have time constraints. My recommendation to you as a Global
          Mobility professional is to be 100% sure of what technology solution
          you need from your relocation provider before going to a relocation
          services or technology RFP. Let me help you with a few ‘Do you need a’
          type questions to help evaluate your needs.
        </p>
        <ol type="A">
          <li class="mb-2">
            Complete technology offering from your relocation provider to
            compete with the technology specialists and bespoke to your needs?
          </li>
          <li class="mb-2">
            Simple, less complex technology platform from your relocation
            provider to provide updates, basic dashboards, and an employee app?
          </li>
          <li>
            A relocation provider with the best internal technology operating
            platform for their day-to-day work and open data sharing
            capabilities?
          </li>
        </ol>
        <p>
          Would a call to help you evaluate what technology approach to take be
          useful?
        </p>
        <p>
          I am available to share my experience and knowledge to help you define
          your technology goals and discuss how they can be achieved.
        </p>
        <p>
          Written by Paul Barnes, Director, Inspire Global Mobility Consulting.
        </p>
        <p>
          Email: <strong>paul@igmobility.com</strong> Mobile: +44 (0) 7534 565
          888
        </p>
      </article>
    </div>
  </Layout>
)

export default blog1
